<template>
  <div class="ml-8" v-if="$store.state.auth.user.roles[0].name">
    <div class="flex justify-between">
      <div>
        <h1 class="appointments text-justify text-xl mt-12 text-appointments">
          System Institutions
        </h1>
        <Button
          title="Add Institution"
          icon="create-user"
          :isSecretary="true"
          route="/institution"
          v-if="$store.state.auth.user.roles[0].name === 'ADMIN'"
        />
      </div>
      <div class="mt-40 md:mt-32 block mr-10">
        <Search
          label="Search by name"
          class="w-50 -ml-12"
          @input="searchInstitution"
        />
      </div>
    </div>
    <div class="flex pl-3 md:hidden" @change="generalCheck($event)">
      <h1 class="pl-2 text-xs">Select ministries</h1>
      <div class="mt-12 -ml-20">
        <AppointmentActionButtons
          @deleted="removedDeletedItems"
          v-if="selectedMinistires.length"
          :isSecretary="true"
          :deleteFill="`${selectedMinistires.length ? '#FB2222' : '#B0B0B0'}`"
          :id="selectedMinistires"
          ACTION="MINISTRY"
        />
      </div>
    </div>
    <div v-if="elementsAvailble">
      <table>
        <thead>
          <tr
            class="flex flex-col flex-no wrap table-row rounded-l-lg rounded-none mb-2 mb-0"
          >
            <th class="p-3 text-left invisible">
              <input type="checkbox" @change="generalCheck($event)" />
            </th>
            <th v-if="fields['name']" class="p-3 text-left">
              <span class="flex text-table text-xs">Institution</span>
            </th>
            <th v-if="fields['status']" class="p-3 text-left">
              <span class="flex text-table text-xs">Status</span>
            </th>
            <th v-if="fields['dateAndTime']" class="p-3 text-left">
              <span class="flex text-table text-xs">Date added</span>
            </th>

            <th
              class="p-3 text-left text-table text-xs flex justify-between"
              v-if="$store.state.auth.user.roles[0].name === 'ADMIN'"
            >
              <p class="md:-ml-6">Actions</p>
              <AppointmentActionButtons
                @deleted="removedDeletedItems"
                v-if="selectedMinistires.length"
                :isSecretary="true"
                :deleteFill="`${
                  selectedMinistires.length ? '#FB2222' : '#B0B0B0'
                }`"
                :id="selectedMinistires"
                ACTION="MINISTRY"
              />
            </th>
          </tr>
        </thead>
        <tbody class="sm:flex-1 sm:flex-none">
          <tr
            v-for="ministry in ministries"
            :key="ministry.id"
            class="sm:flex sm:flex-col sm:flex-no sm:wrap sm:table-row sm:mb-2 sm:mb-0 h-80 main-header sm:header sm:shadow-lg"
          >
            <td class="sm:p-3 text-left">
              <input type="checkbox" @change="onChange(ministry.id)" />
            </td>
            <td v-if="fields['name']" class="sm:p-3 flex text-left">
              <p
                class="text-xs mt-4 -ml-2 md:mt-2 sm:text-sm sm:text-values title"
              >
                {{ ministry.name }}
              </p>
            </td>
            <td v-if="fields['status']" class="p-3 sm:font-semibold">
              <div class="w-24 sm:w-24 sm:h-4 sm:px-0.1 sm:py-0.1 bg-white">
                <span class="sm:text-values text-xs sm:text-sm sm:title">{{
                  ministry.status
                }}</span>
              </div>
            </td>
            <td v-if="fields['dateAndTime']" class="p-3 text-left">
              <span class="sm:text-values sm:text-sm">{{
                ministry.createdAt
              }}</span>
            </td>

            <td
              class="text-values text-sm p-3 flex"
              v-if="$store.state.auth.user.roles[0].name === 'ADMIN'"
            >
              <div
                class="w-24 rounded text-view cursor-pointer h-7 bg-table p-0.5 pt-1 pb-1 text-center"
                @click="editMinistry(ministry)"
              >
                <span class="view">Edit</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailble"
      :isLoading="loading"
      type="Institution"
    />
  </div>
</template>
<script>
import Button from "/src/components/Listings/Button.vue";
import AppointmentActionButtons from "/src/components/Listings/AppointmentActionButtons.vue";
import Search from "/src/components/searchInput.vue";
import Api from "/src/services/Apis";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import { mapActions } from "vuex";

export default {
  name: "MinistriesListing",
  components: {
    Button,
    AppointmentActionButtons,
    Search,
    ElementsNotFound,
  },
  data() {
    return {
      numberOfChecks: 0,
      checkDisabled: true,
      elementsAvailble: true,
      loading: true,
      ministries: [],
      selectedMinistires: [],
      fields: [],
    };
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    removedDeletedItems() {
      this.ministries = this.ministries.filter(
        (e) => e.id != this.selectedMinistires[0]
      );
      this.selectedMinistires = [];
      this.$store.dispatch("notification/SET_NOTIFICATION", {
        msg: "Institution deleted sucessfully",
        uptime: 3000,
      });
    },
    onChange(id) {
      // let index = this.selectedMinistires.indexOf(id);
      // if (index > -1) {
      // this.selectedMinistires = [];
      // } else {
      this.selectedMinistires = [id];
      // }
    },
    generalCheck(e) {
      if (e.target.checked) {
        this.numberOfChecks++;
      } else {
        this.numberOfChecks--;
      }
      if (this.numberOfChecks > 0) {
        for (let ministry of this.ministries) {
          this.selectedMinistires.push(ministry.id);
        }
      } else {
        this.selectedMinistires = [];
      }
    },
    async getInstitutions() {
      await Api.get("institutions")
        .then((response) => {
          this.ministries = response.data.content;
          if (!this.ministries.length) {
            this.elementsAvailble = false;
          } else {
            this.elementsAvailble = true;
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async searchInstitution(name) {
      if (name === "") {
        this.getInstitutions();
      } else {
        await Api.get("institutions/search/?name=" + name)
          .then((resp) => {
            this.ministries = resp.data;
            if (!this.ministries.length) {
              this.elementsAvailble = false;
            } else {
              this.elementsAvailble = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editMinistry(ministry) {
      this.$store.commit("admin/setSelectedInstitution", ministry);
      this.$router.push(`/update-institution/${ministry.id}`);
    },
  },
  computed: {
    // disableBtn() {
    //   if (this.selectedMinistires.length > 0) {
    //     return (this.checkDisabled = false);
    //   } else if (this.selectedMinistires.length <= 0) {
    //     return (this.checkDisabled = true);
    //   }
    // },
  },
  async created() {
    this.fields = await this.getFields("institutions_table");
    this.getInstitutions();
  },
};
</script>
<style scoped>
table {
  width: 97%;
  border-collapse: collapse;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.title {
  font-weight: 600;
}
.header {
  background: white;
  height: 3.5rem;
}
.names {
  margin: 0 3rem 0 -8rem;
}

@media only screen and (max-width: 760px),
  (min-device-width: 250px) and (max-device-width: 640px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    background: white;
    width: 20rem;
    margin: 1rem 0 0 0;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%);
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    text-align: justify;
    color: #000000;
  }

  td:nth-of-type(2):before {
    content: "Institutions";
  }
  td:nth-of-type(3):before {
    content: "Status";
  }
  td:nth-of-type(4):before {
    content: "Date Added";
  }
  td:nth-of-type(5):before {
    content: "Actions";
  }
  td:nth-of-type(6):before {
    content: "Actions";
  }
}
</style>
